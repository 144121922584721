<template>
    <div class="tableBox">
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" :commonMethods.sync="commonMethods" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './sn-table-group-role';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'role',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'roleinfo',
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            keyword: '', // 关键字
            exportItemId: 'role'
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'getRoleList',
            'deleteRole'
        ]),
        async getTableData() {
            let res = await this.getRoleList({
                keyword: this.keyword,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu
            });
            this.tableGroupAttributes.snTable.table.data = res.Data;
            this.tableGroupAttributes.snPage.count = Number(res.Total);
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        async delData(id, done) {
            let res = await this.deleteRole({
                ids: id
            });
            this.delOver(res, done);
        },
    }
};
</script>
